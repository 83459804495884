.menu {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 200px; /* Adjust the margin to create space for the footer */
}

.menu .menuTitle {
  font-family: sans-serif;
  font-size: 60px;
  font-style: italic;
}

.menuList {
  width: 70vw;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  margin-bottom: 20px; /* Adjust the margin as needed */
}

/* MENU ITEM STYLING */

.menuItem h1,
.menuItem p {
  margin-left: 20px;
}

@media only screen and (max-width: 1300px) {
  .menuList {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .menuList {
    width: 20px;
    height: auto;
    margin-bottom: 100px;
    display: block;
    justify-content: center;
    align-items: center;
  }
  .imag {
    width: 430px;
  }
  .menu {
    width: 100%;
    height: auto;
    display: block;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
