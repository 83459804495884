.pdf-downloader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.download-button {
  padding: 8px 16px; /* Reduced padding */
  font-size: 14px; /* Reduced font size */
  font-weight: bold;
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.download-button:hover {
  background-color: #f66;
}
