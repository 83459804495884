#winePhoto {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
}

/* Style for the restaurant photos grid */
#restaurantPhotos {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Style for individual restaurant photos */
.restaurantPhoto {
  width: 30%;
  border-radius: 8px;
  object-fit: cover;
}

@media only screen and (max-width: 900px) {
  #winePhoto {
    margin-top: 350px;
  }
}
